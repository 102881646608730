.grecaptcha-badge {
    visibility: hidden;
}

body.recaptcha {
    .grecaptcha-badge {
        visibility: visible;
        margin-bottom: 50px;
    }
}

.right-section-btn {
    text-align: right;
    margin-bottom: 13px;
}
