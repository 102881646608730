@import '_common';

$bodyBg: #17212f !default;
$textColor:rgba(255,255,255,.87) !default;
$textSecondaryColor: rgba(255,255,255,.60) !default;
$dividerColor:#304562 !default;
$itemHoverBg:rgba(255,255,255,.03) !default;
$focusShadow:0 0 0 1px #BBDEFB !default;
$linkColor: var(--primary-light-color, #90CAF9) !default;
$overlayBorder:1px solid $dividerColor !default;
$overlayShadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12) !default;
$btnBgHover: #81d4fa0a;

//card
$cardBg: #1f2d40 !default;
$cardBorder: 0 none !default;

//topbar
$topbarBg: #1f2d40 !default;
$topbarBottomBorder: 1px solid transparent !default;
$topbarTextColor: $textColor !default;
$topbarIconBg: transparent !default;
$topbarIconHoverBg: $itemHoverBg !default;
$pageTitleColor: #F1F2F3 !default;

// icon
$defaultIconColor: rgba(255, 255, 255, 0.87) !default;

//accent
$accentColor: #90CAF9 !default;
$accentTextColor: #121212 !default;

//sidebar
$sidebarWidth: 17rem !default;
$slimSidebarWidth: 6rem !default;

//search
$searchBg:#17212f !default;
$searchBorder: 1px solid $dividerColor !default;

//footer
$footerBg: #1f2d40 !default;
$footerBorder: 1px solid rgba(179, 179, 188, 0.25) !default;

//sidebar right
$rightSidebarWidth: 230px !default;
$rightSidebarBg: #1f2d40 !default;

//table
$tableTextColor: #F1F2F3 !default;
$tableIconColor: #5E6B7D !default;
$tableDetailColor: #5E6B7D !default;
$tableButtonColor: #D9DADD !default;
$tableButtonBgColor: #304562 !default;
$tableDetailBorderColor: rgb(48 69 98 / 40%) !default;
$historyBorderColor: #1B2637 !default;

//dialog
$dialogTitleColor: #F1F2F3 !default;
$dialogButtonTextColor: #D9DADD !default;
$historyItemBg: rgb(48 69 98 / 25%) !default;
$historyTopColor: #DEE0E3 !default;
$historyBottomColor: #D9DADD !default;

//filter dropdown
$filterDropdownBg: #304562 !default;
$filterDropdownBgActive: #1B2637 !default;
$filtersDropdownBtnBg: #304562 !default;
$filtersTextColor: #DEE0E3 !default;


//reusable color variables (by designer)
$surface000:#1F2D40 !default;
$surface100:#17212F !default;
$surface200:#1B2637 !default;
$surface300:#304562 !default;
$surface400:#5E6B7D !default;
$surface500:#89929F !default;
$surface600:#A6ACB5 !default;
$surface700:#D9DADD !default;
$surface800:#DEE0E3 !default;
$surface900:#F1F2F3 !default;
