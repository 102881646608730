$gutter: 1rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
@import "ag-grid-community/dist/styles/ag-theme-balham.css";
@import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
@import "assets/demo/flags/flags.css";
@import "assets/demo/documentation.scss";
@import "assets/sass/general/general.scss";
@import 'assets/sass/variables/layout/layout_dim';
@import 'assets/sass/variables/layout/layout_light';

@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin";
@import "ag-grid-community/src/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark-mixin";

* {
    touch-action: manipulation;
    user-select: text;
}

p-toast, .p-toast, p-toastitem {
    touch-action: none !important;

    * {
        touch-action: none !important;
    }
    .p-toast-icon-close {
        touch-action: auto;
    }
}

/*
    AG Table styles start
 */
.table-wrapper-full-page-height ag-grid-angular {
    height: calc(100vh - 160px);
}

.ag-with-total ag-grid-angular {
    height: calc(100vh - 221px);
}

@media screen and (min-width: 1092px) {
    .ag-with-total ag-grid-angular {
        height: calc(100vh - 215px);
    }
}

.ag-total .ag-cell-value {
    font-weight: bold;
}

.with-borders {

    .ag-theme-alpine {
        .ag-ltr .ag-cell {
            border-left-color: #dde2eb;
        }

        .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
            border-left-color: #dde2eb;
        }
    }
    .ag-theme-alpine-dark {
        .ag-ltr .ag-cell {
            border-left-color: #304562;
        }
        .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
            border-left-color: #304562;
        }
    }

}

.ag-theme-alpine-dark {
    --ag-selected-row-background-color: #304562;

    @include ag-theme-alpine-dark((
        // use theme parameters where possible
        font-family: $fontFamily,
        background-color: $topbarBg,
        odd-row-background-color: #17212F,
        header-background-color: $bodyBg,
        border-color: $dividerColor,
        secondary-border-color: $dividerColor,
        control-panel-background-color: $bodyBg,
        subheader-background-color: $bodyBg,
        range-selection-border-color: #629aeb33,
    ));

    input[class^=ag-][type=text] {
        border-color: #1B2637;
        background: #1F2D40;
    }

    .ag-floating-filter-button-button {
        color: #89929F;
    }

    .ag-header-cell-text,
    .ag-row {
        color: $tableTextColor;
    }

    .ag-row-hover {
        background: #1B2637;
    }

    .ag-icon-filter {
        cursor: pointer;
    }
}

.view-notes-dialog .ag-theme-alpine-dark {
    @include ag-theme-alpine-dark((
        // use theme parameters where possible
        background-color: $bodyBg,
        odd-row-background-color: $bodyBg,
        header-background-color: $bodyBg,
        border-color: $bodyBg,
        secondary-border-color: $bodyBg,
        control-panel-background-color: $bodyBg,
        subheader-background-color: $bodyBg
    ));

    .ag-row-hover {
        background: transparent;
    }

    .ag-group-expanded {
        background-color: $bodyBg;
    }
}

.ag-theme-alpine {
    --ag-selected-row-background-color: #EFF8FF;

    @include ag-theme-alpine((
        font-family: $fontFamily,
        background-color: #FFFFFF,
        odd-row-background-color: #FCFCFD,
        header-background-color: #FCFCFD,
        border-color: #E4E5F1,
        range-selection-border-color: #e4e4e4,
    ));

    .ag-header-cell-text,
    .ag-row {
        color: #343A40;
    }

    .ag-group-contracted,
    .ag-group-expanded {
        color: #CBCBD8;
    }

    .ag-row-hover {
        background: transparent;
    }

    .ag-icon-filter {
        cursor: pointer;
    }

    .ag-cell-value .ag-row-group-leaf-indent {
        margin: 0 ;
    }
}

.ag-header-cell {
    font-weight: 600;
    font-size: 0.928rem;
}

.ag-cell {
    font-weight: 400;
    font-size: 0.928rem;

    > span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: transparent;
}

.ag-layout-auto-height .ag-center-cols-clipper,
.ag-layout-auto-height .ag-center-cols-container,
.ag-layout-print .ag-center-cols-clipper,
.ag-layout-print .ag-center-cols-container {
    min-height: 80px !important;
}

.radiused .ag-root-wrapper {
    border-radius: 5px;
}

/*
    AG Table styles end
 */


/*
    Client's note styles start
 */
.name-surname {
    margin-left: 4px;
    color: var(--primary-color);;
}

.client-notes-icon {
    display: inline-flex;
    vertical-align: middle;
    font-size: 24px;
    cursor: pointer;

    &.view-player-notes {
        color: var(--primary-color);;
    }
}
/*
    Client's note styles end
 */

/*
    Dialog styles end
 */
.p-dialog {
    .p-dialog-footer {
        width: 100%;
        height: 55px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        border-top: 0 none;
        padding: 9.5px;
        box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.05);

        button {
            margin: 0 0.5rem 0 0;
            width: auto;
        }
    }
}

.dialog-dropdown .p-dropdown-items-wrapper {
    max-height: 110px !important;
}

@media screen and (max-width: 991px) {
    .p-dialog {
        width: 90% !important;
    }
}
/*
    Dialog styles end
 */


/*
    Calendar styles end
 */
@media only screen and (max-width: 769px) {
    p-calendar{
        left: 0 !important;
    }

    .p-datepicker td>span {
        margin: 3px auto !important;
    }
}
/*
    Calendar styles end
 */

/*
    Global styles start
 */
.link-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.link {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    // status color
    background: red;
}

.p-toolbar {
    background: transparent;
    border: none;
    padding: 0;
    margin-bottom: 1rem;
}

.no-data-found {
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 17.5px;
    line-height: 24px;

    i {
        font-size: 22px;
    }
}
/*
    Global styles end
 */

/*
    Notes' styles start
 */
app-ggr-component,
app-notes-component,
app-comment-component,
app-notes-edit-component
{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

app-view-notes {
    background: inherit;
}

.add-note-dialog,
.history-view-dialog,
.view-notes-dialog {
    height: 515px;
}

.add-note-dialog {
    padding-bottom: 32px;
    overflow: hidden;
}

.view-notes-dialog {
    .p-dialog-header-icons {
        z-index: 999;
    }
}
/*
    Notes' styles end
 */

/*
    Client's note styles start
 */
.name-surname {
    margin-left: 4px;
    color: var(--primary-color);;
}

.client-notes-icon {
    display: inline-flex;
    vertical-align: middle;
    font-size: 24px;
    cursor: pointer;

    &.view-player-notes {
        color: var(--primary-color);;
    }
}
/*
    Client's note styles end
 */


.comment-button {
    min-width: 144px;
    max-width: 144px;
    justify-content: flex-start;
}

/*
    Info pages' styles start
 */
.main-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.5px 20px;

    .title {
        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
    }

    .action-buttons {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        justify-content: space-between;

        .action-block {
            display: flex;
            align-items: center;
        }

        .icon-notes, .icon-password, .icon-message, .icon-edit {
            padding-right: 0;
            margin-right: 0;
            font-size: 15px;
        }
    }
}

.detail {

    &-inner {
        height: auto;
    }

    &-key {
        line-height: 15px;
        margin-bottom: 0;
    }

    &-value {
        line-height: 18px;

        &-green {
            color: #79F2B8;
        }

        &-red {
            color: #EE6464;
        }

        &-bg-green {
            background: rgb(121, 242, 184);
            color: #3F3F3F;
        }

        &-bg-red {
            background: rgb(255, 214, 218);
            color: #3F3F3F;
        }
    }
}

.player-info-item,
.detail-inner-wrapper {
    .detail-inner {
        &:last-child {
            border-bottom: none;
        }
    }
}

.player-info {
    &-top-section {
        margin-top: -28px;
        padding-bottom: 14px;

        i {
            font-size: 21px;
            margin-right: 13.5px;
        }

        &-label {
            font-weight: 600;
            font-size: 11px;
        }
    }

    &-bottom-section {
        margin-left: -28px;
        margin-right: -28px;
        display: none;

        .detail {
            padding-top: 10px;
            padding-bottom: 10px;

            &-inner {
                padding: 10px 0;
            }

            &-key {
                margin-bottom: 5px;
            }
        }
    }

    &-right-section {
        border-radius: 12px;
        margin-top: 15px;
        padding: 4px 12px;

        > div {
            padding: 11px 0 5px 0;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(228, 229, 241, 0.4);
            }
        }

        .detail-key {
            margin-bottom: 0;
        }

        p-progressbar {
            width: 50%;
        }
    }
}

.top-section-item {
    flex: 0 0 10%;
    min-width: 140px;
    max-width: 190px;
    display: flex;
    align-items: center;

    .detail-inner {
        width: 80%;
    }
}

.player-info-item {
    width: 80%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding-top: 9px;
    padding-bottom: 9px;

    .detail {
        &-key {
            margin-right: 10px;
        }

        &-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
        }
    }
}

.tabs-wrapper {
    margin-top: 16px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .p-tabmenu .p-tabmenu-nav {
        border-radius: 8px;

        &-container {
            border-radius: 8px 8px 0 0;
        }

        .p-menuitem-text {
            white-space: nowrap;
        }

        &-next {
            box-shadow: -2px 0 5px rgb(0 0 0 / 8%);
            border-radius: 0 8px 0 0;
        }

        &-prev {
            box-shadow: 2px 0 5px rgb(0 0 0 / 8%);
            border-radius: 8px 0 0 0;
        }

        &-btn {
            width: 22px;
            height: 43px;
            touch-action: manipulation;

            span {
                font-size: 9px;
            }
        }

        .p-tabmenuitem {
            &.p-highlight {
                .p-menuitem-link {
                    font-weight: 600;
                    font-size: 14px;
                }
            }

            .p-menuitem-link {
                font-weight: 400;
                font-size: 14px;
                padding: 1rem 20px;
                border-width: 0 0 1px 0;

                &:not(.p-disabled):focus {
                    box-shadow: none;
                }
            }
        }
    }

    .table-wrapper {
        padding: 15px 20px;
    }
}

@media screen and (min-width: 1091px) {
    .top-section-item {
        margin-right: 15px;
    }

    .player-info {
        &-bottom-section {
            display: flex;
        }

        &-right-section {
            margin-right: 16px;
        }
    }

    .more-less-wrapper {
        display: none;
    }
}

@media screen and (max-width: 1090px) {
    .player-info {
        &-top-section {
            padding: 0 19px;
            margin-left: -28px;
            margin-right: -28px;

            > div {
                justify-content: space-between;
            }
        }

        &-right-section {
            border: none;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;

            > div {
                padding-top: 5px;
            }
        }
    }

    .top-section-item {
        flex: 0 0 49%;
        min-width: 49%;
        max-width: 49%;

        &:nth-last-of-type(-n+2) {
            border-bottom: none;
        }
    }
}

/*
    Info pages' styles end
 */
